@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Thin.woff2') format('woff2'),
        url('../assests//fonts/Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../assests//fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Regular.woff2') format('woff2'),
        url('../assests//fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Medium.woff2') format('woff2'),
        url('../assests//fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Light.woff2') format('woff2'),
        url('../assests//fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-ExtraLight.woff2') format('woff2'),
        url('../assests//fonts/Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('../assests//fonts/Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Bold.woff2') format('woff2'),
        url('../assests//fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Black.woff2') format('woff2'),
        url('../assests//fonts/Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Black.woff2') format('woff2'),
        url('../assests//fonts/Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('../assests//fonts/Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Bold.woff2') format('woff2'),
        url('../assests//fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Light.woff2') format('woff2'),
        url('../assests//fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-ExtraLight.woff2') format('woff2'),
        url('../assests//fonts/Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Medium.woff2') format('woff2'),
        url('../assests//fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Thin.woff2') format('woff2'),
        url('../assests//fonts/Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../assests//fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assests//fonts/Inter-Regular.woff2') format('woff2'),
        url('../assests//fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
